import React from "react";

import s from "./LocationComponent.module.scss";

export default function Location({
  name,
  phone,
  email,
  fax,
  Embed,
  rootClass
}) {
  return (
    <div className={`${s.location} ${rootClass ? rootClass : ""}`}>
      <Embed height="300px" />
      <h3 className={s.name}>{name}</h3>
      {email ? <p className={s.email}>{email}</p> : null}
      {phone ? (
        <p className={s.phone}>
          <i class="fas fa-phone"></i>
          {phone}
        </p>
      ) : null}
      {fax ? (
        <p className={s.fax}>
          <i class="fas fa-fax"></i>
          {fax}
        </p>
      ) : (
        <p className={s.fax}>&nbsp;</p>
      )}
    </div>
  );
}
