import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

//components
import useSetPageData from "../utils/useSetPageData";
// import AnimatedForm from "../components/in-pages/ContactFormAnimated";
import ComponentHeader from "../components/panels/ComponentHeader";
import LocationComponent from "../components/non-panels/LocationComponent";
// import ShortForm from "../components/forms/SmallSubscription";
import HubSpotForm from "../components/non-panels/forms/HubSpotForm";
import { ContactFormReplacementMessage } from "../components/non-panels/forms/replacementElements";
import SEO from "../components/seo";

//images
import backgroundImg from "../images/Image_Contact_Contact_US.png";

// styles
import s from "./contact.module.scss";
// import autoprefixer from "autoprefixer";

const locations = [
  {
    name: "Global HQ",
    phone: "281-503-7002",
    fax: "281-520-3598",
    Embed: ({ height }) => (
      <iframe
        title="hq"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3463.069225053749!2d-95.78620908489117!3d29.7756397819809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864126c2e06d5229%3A0xebba646331768cdd!2s440%20Cobia%20Dr%20STE%201701%2C%20Katy%2C%20TX%2077494!5e0!3m2!1sen!2sus!4v1585764189352!5m2!1sen!2sus"
        width="100%"
        height={`${height}`}
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    )
  },
  {
    name: "US Ohio",
    phone: "281-503-7002",
    fax: "281-520-3598",
    Embed: ({ height }) => (
      <iframe
        title="ohio"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3052.1098732186642!2d-83.13333524814759!3d40.095263179302584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8838932f8a3eefb7%3A0xd15009d0db426ee!2s555%20Metro%20Pl%20N%20%23100%2C%20Dublin%2C%20OH%2043017!5e0!3m2!1sen!2sus!4v1585764256228!5m2!1sen!2sus"
        width="100%"
        height={`${height}`}
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    )
  },
  {
    name: "Malaysia",
    phone: "03-2276-3999",
    fax: "03 2276 4399",
    Embed: ({ height }) => (
      <iframe
        title="malaysia"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.8413506430907!2d101.6852818514935!3d3.1365760977048738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4958f29afc35%3A0xce27c31f8af16307!2sCommon%20Ground%2C%20Q%20Sentral!5e0!3m2!1sen!2sus!4v1585764337466!5m2!1sen!2sus"
        width="100%"
        height={`${height}`}
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    )
  },
  {
    name: "India",
    phone: "040-29881990",
    Embed: ({ height }) => (
      <iframe
        title="india"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.249301575673!2d78.55311691410644!3d17.399819588071185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9924f494f13d%3A0x90acab3092a6c0f3!2sNitco%20Outsourcing%20Private%20Limited!5e0!3m2!1sen!2sus!4v1639182852322!5m2!1sen!2sus"
        width="100%"
        height={`${height}`}
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    )
  }
];

const ContactPage = ({ location, data }) => {
  const { SEO: seo, hero } = data.strapiPContact;

  useSetPageData(location);

  return (
    <>
      {/* <Hero
        heading={hero[0].main_heading}
        icon={hero[0].contact_hero_image.childImageSharp.fixed}
      /> */}
      <SEO
        {...{
          title: `${hero[0].main_heading}`,
          description: seo.description
        }}
      />

      <div className={`container-fluid panel-padding `}>
        <div className={`container ${s.mainSection}`}>
          <div className={`row`}>
            <div
              className={`col-sm-12 col-md-6 ${s.backgroundTile} pr-0`}
              style={{ backgroundImage: `url(${backgroundImg})` }}
            >
              <h1>
                Contact<span className={`${s.usMobile}`}>&nbsp;Us</span>
              </h1>
              <div className={`${s.overlay}`}>
                <h3 className={s.textIntro}>Have any questions?</h3>
                <h2 className={s.textMain}>Let's Talk</h2>
                <p className={s.textLong}>
                  We have a talented team that is excited to help you with your
                  needs! Contact us today to schedule a free consultation and
                  experience the difference with NITCO.
                </p>
              </div>
            </div>
            <div
              className={`col-sm-12 col-md-6 ${s.contactPadding}`}
              style={{ minHeight: "700px" }} // Not ideal, but prevents skipping.
            >
              <h2 className={`${s.usDesktop}`}>Us</h2>
              {/* <ShortForm rootStyle={{ margin: "0 auto" }} /> */}
              <HubSpotForm
                className={s.mdPadding30}
                formId="591b39dd-a13c-4a1b-a1e4-c941be318797"
                replacementElement={<ContactFormReplacementMessage />}
              />
            </div>
          </div>
        </div>
      </div>
      <br />

      <ComponentHeader
        bgColor={"light-gray"}
        textColor={"purple"}
        text={"Locations"}
      />

      <div className={`container-fluid panel-padding ${s.locationBackground}`}>
        <div className={`container`}>
          <div className={`row`}>
            {locations.map(loc => (
              <div className={`col-sm-12 col-md-6`}>
                <LocationComponent {...loc} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className={layoutStyle.container}>
        <div className={s.mapsSection}>
          <div className={s.maps}>
            {locations.map(loc => (
              <LocationComponent {...loc} />
            ))}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    strapiPContact {
      SEO {
        description
      }
      hero {
        main_heading
        main_subheading
        contact_hero_image {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
